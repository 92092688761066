<template>
  <section>
    <div class="header pb-5">
      <app-navigation />

      <br />

      <div class="header-title">
        <div class="row">
          <div class="col-12 text-center">
            <h1>Portfolio</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <div class="header-breadcrumb">
              <router-link to="/"><i class="fa fa-fw fa-home"></i></router-link> / Portfolio
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main pt-9">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="card mb-2 mt-2">
              <div class="card-body">
                <h5 class="card-title">Interview Board</h5>
                <p class="card-text">Basic interview portal.</p>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">Framework-less PHP</li>
                <li class="list-group-item">MySQL Database</li>
                <li class="list-group-item">Bootstrap 4 + jQuery</li>
              </ul>
              <div class="card-body">
                <div class="d-grid">
                  <a href="https://github.com/adamlmiller/interview-board" class="btn btn-success text-white" target="_blank">Github</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="card mb-2 mt-2">
              <div class="card-body">
                <h5 class="card-title">Ansible Playbooks</h5>
                <p class="card-text">A few basic Ansible playbooks.</p>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">Provision AWS EC2 Instance</li>
                <li class="list-group-item">CentOS 6 LAMP</li>
                <li class="list-group-item">Provision VMware Guest</li>
              </ul>
              <div class="card-body">
                <div class="d-grid">
                  <a href="https://github.com/adamlmiller/ansible-playbooks" class="btn btn-success text-white" target="_blank">Github</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="card mb-2 mt-2">
              <div class="card-body">
                <h5 class="card-title">cashable</h5>
                <p class="card-text">A custom invoice management system</p>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">VueJS + Bootstrap</li>
                <li class="list-group-item">NodeJS + Express API</li>
                <li class="list-group-item">MySQL Database</li>
              </ul>
              <div class="card-body">
                <div class="d-grid">
                  <a href="#" class="btn btn-success text-white">Please ask for a demo.</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="card mb-2 mt-2">
              <div class="card-body">
                <h5 class="card-title">Law Enforcement Alerting Portal</h5>
                <p class="card-text">Disseminate alerts over the wire</p>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">CakePHP / MySQL Database</li>
                <li class="list-group-item">Bootstrap 4 + jQuery</li>
                <li class="list-group-item">Email, Fax, SMS, FEMA</li>
              </ul>
              <div class="card-body">
                <div class="d-grid">
                  <a href="http://amberalert.com" class="btn btn-success text-white" target="_blank">Go to amberalert.com</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="card mb-2 mt-2">
              <div class="card-body">
                <h5 class="card-title">FarmFlight</h5>
                <p class="card-text">Agricultural Drone Mapping</p>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">VueJS + NodeJS + Express</li>
                <li class="list-group-item">MySQL Database</li>
                <li class="list-group-item">AWS Cloudfront + S3</li>
              </ul>
              <div class="card-body">
                <div class="d-grid">
                  <a href="https://farmflightaz.com" class="btn btn-success text-white" target="_blank">Go to FarmFlight</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="card mb-2 mt-2">
              <div class="card-body">
                <h5 class="card-title">Puretrim Power</h5>
                <p class="card-text">Mobile app for Puretrim customers</p>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">Cordova + Bootstrap + jQuery</li>
                <li class="list-group-item">Apple iOS and Google Android</li>
                <li class="list-group-item">Single Code Base / Multi Platform</li>
              </ul>
              <div class="card-body">
                <div class="d-grid">
                  <a href="https://apps.apple.com/us/app/puretrim-power/id1127507032" class="btn btn-success text-white" target="_blank">Go to AppStore</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="card mb-2 mt-2">
              <div class="card-body">
                <h5 class="card-title">Puretrim Fit</h5>
                <p class="card-text">Mobile app for tracking weightloss</p>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">Ionic + Angular</li>
                <li class="list-group-item">SlimPHP API + Eloquent ORM</li>
                <li class="list-group-item">Take pictures, upload video, etc</li>
              </ul>
              <div class="card-body">
                <div class="d-grid">
                  <a href="https://apps.apple.com/us/app/puretrim-fit/id1482869722" class="btn btn-success text-white" target="_blank">Go to AppStore</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="card mb-2 mt-2">
              <div class="card-body">
                <h5 class="card-title">AlertGPS Enterprise Portal</h5>
                <p class="card-text">Portal for tracking GPS locator devices</p>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">CakePHP + Bootstrap</li>
                <li class="list-group-item">Redis / MongoDB / MySQL</li>
                <li class="list-group-item">AWS EC2 / RDS / S3</li>
              </ul>
              <div class="card-body">
                <div class="d-grid">
                  <a href="https://alertgps.com" class="btn btn-success text-white" target="_blank">Go to AlertGPS</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer />
  </section>
</template>

<script>
import appNavigation from '../components/Navigation'
import appFooter from '../components/Footer.vue'

export default {
  components: {
    appNavigation,
    appFooter
  },
  head: {
    title: "My Portfolio |  DevOps Engineer, AWS Engineer, Software Developer, VueJS Developer, NodeJS Developer, PHP Developer, and more | Adam L Miller",
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'I am a seasoned veteran in AWS DevOps as well as Software Development using VueJS, NodeJS, PHP and more!'
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content: 'devops engineer, aws engineer, software developer, vuejs developer, nodejs developer, php developer'
      }
    ]
  }
}
</script>